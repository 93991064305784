import React from "react";
import howToGif from "../../assets/how-to.gif";
import { Introduction } from "./Introduction";
import "./hero.scss";

export const Hero: React.FC = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    return (
        <section className="hero is-dark is-fullheight" id="hero">
            <div className="hero-body">
                <div className="container">
                    <div className="columns">
                        <div className="column is-5-desktop is-8-tablet">
                            <Introduction />
                        </div>
                        <div className="column gif-column is-hidden-touch">
                            <div className="card">
                                <img
                                    alt="Github Compare how-to"
                                    src={howToGif}
                                    className="is-block"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
