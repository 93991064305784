import React, { useContext, useEffect, useState } from "react";
import { ReposContext } from "../../providers/ReposContext";
import { Repo } from "../../repo/repo";
import { constructCardDetails } from "../../helpers/construct-card-details";
import { Item } from "./Item";
import { Title } from "./Title";
import "./card.scss";

export interface CardProps {
    repo: Repo;
}

export const Card: React.FC<CardProps> = ({ repo }) => {
    const { removeRepo } = useContext(ReposContext);

    const {
        id,
        full_name,
        owner: { avatar_url },
        html_url: url
    } = repo;

    const details = constructCardDetails(repo);

    const [fadeIn, setFadeIn] = useState(true);
    useEffect(() => {
        setTimeout(() => setFadeIn(false), 0);
    }, [fadeIn]);

    return (
        <div className="column is-3-desktop is-6-tablet" key={id}>
            <div className={fadeIn ? "repo-card fade-in" : "repo-card"}>
                <Title
                    full_name={full_name}
                    avatar_url={avatar_url}
                    url={url}
                />
                <hr />
                <div className="repo-card-details">
                    {details.map(({ icon, description, value }, index) => (
                        <Item
                            key={index}
                            index={index}
                            icon={icon}
                            description={description}
                            value={value}
                        />
                    ))}
                </div>
                <div className="repo-card-footer">
                    <p
                        className="has-text-centered"
                        onClick={() => removeRepo(repo.full_name)}
                    >
                        Remove repo
                    </p>
                </div>
            </div>
        </div>
    );
};
