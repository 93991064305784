import React from "react";
import { GAEvent } from "../helpers/tracking";

interface LinkProps {
    eventLabel?: string;
    href: string;
    style?: {};
}
export const Link: React.FC<LinkProps> = ({
    eventLabel,
    href,
    style = {},
    children
}) => {
    const handleClick = () => {
        if (!eventLabel) {
            return;
        }

        GAEvent({ category: "link", action: "click", label: eventLabel });
    };
    return (
        <a
            onClick={handleClick}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            style={{ ...style }}
        >
            {children}
        </a>
    );
};
