import React from "react";

export const PlaceholderCard: React.FC = () => {
    const handleClick = () => {
        const input = document.querySelector(
            "#select > input"
        ) as HTMLInputElement;
        input.focus();
    };
    return (
        <div className="column is-3-desktop is-6-tablet">
            <div className="repo-card placeholder-card" onClick={handleClick}>
                <div className="add">+</div>
                <p>Add another repository</p>
            </div>
        </div>
    );
};
