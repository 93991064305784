import axios from "axios";
import {
    FETCH_REPOS_FUNCTION,
    REPO_SEPARATOR,
    SEARCH_REPO_FUNCTION
} from "../helpers/constants";
import { isDevelopment } from "../helpers/environments";
import { RepoSearchResults, REPO_RESULTS } from "../helpers/repo-results";
import { GAEvent } from "../helpers/tracking";
import { Repo } from "./repo";

export const searchForRepo = async (value: string) => {
    if (isDevelopment) {
        const repos = REPO_RESULTS.items;
        return Promise.resolve(repos);
    }

    GAEvent({ category: "search", action: "input", label: value });

    try {
        const response = await axios.get<RepoSearchResults>(
            `${SEARCH_REPO_FUNCTION}?repo=${value}`
        );

        const repos = response.data.items;

        return Promise.resolve(repos);
    } catch (error) {
        console.error(error);
        return Promise.reject(error);
    }
};

export const fetchRepos = async (queryList: string) => {
    if (isDevelopment) {
        const repos: Repo[] = [];

        const reposList = queryList.split(REPO_SEPARATOR);

        reposList.forEach((full_name) => {
            const repo = REPO_RESULTS.items.find(
                (r) => r.full_name === full_name
            );

            if (repo) {
                repos.push(repo);
            }
        });

        return Promise.resolve(repos);
    }

    try {
        const response = await axios.get<Repo[]>(
            `${FETCH_REPOS_FUNCTION}?repos=${queryList}`
        );

        const repos = response.data;

        return Promise.resolve(repos);
    } catch (error) {
        console.error(error);
        return Promise.reject(error);
    }
};
