import React from "react";
import "./loading-card.scss";

export const LoadingCard: React.FC = () => {
    return (
        <div className="column is-3-desktop is-6-tablet">
            <div className="loading-card">
                <div className="bars">
                    <div className="bar loading"></div>
                    <div className="bar bar2 loading"></div>
                    <div className="bar bar2 loading"></div>
                    <div className="bar bar2 loading"></div>
                    <div className="bar bar2 loading"></div>
                    <div className="bar bar2 loading"></div>
                    <div className="bar bar2 loading"></div>
                    <div className="bar bar2 loading"></div>
                </div>
            </div>
        </div>
    );
};
