import React, { useContext, useEffect, useState } from "react";
import { getReposNamesList } from "../../helpers/query-list";
import history from "../../history";
import { ReposContext } from "../../providers/ReposContext";
import { Repo } from "../../repo/repo";
import { Card } from "../Card/Card";
import { LoadingCard } from "../LoadingCard/LoadingCard";
import { SocialShare } from "../SocialShare/SocialShare";
import { PlaceholderCard } from "../Card/PlaceholderCard";

export const ReposContainer: React.FC = () => {
    const { getRepos, isLoading } = useContext(ReposContext);
    const [repos, setRepos] = useState<Repo[]>([]);

    useEffect(() => {
        const reposList = getReposNamesList(history.location.pathname);
        const allRepos = getRepos(reposList);
        setRepos(allRepos);

        const unlisten = history.listen(({ pathname }) => {
            const reposList = getReposNamesList(pathname);
            const newRepos: Repo[] = getRepos(reposList);

            setRepos(newRepos);
        });

        return () => unlisten();
    }, [getRepos]);

    if (isLoading) {
        const reposList = getReposNamesList(history.location.pathname);
        const renderLoadingCards = reposList.map((_, i) => (
            <LoadingCard key={i} />
        ));

        return (
            <section className="section">
                <div className="container">
                    <div className="columns is-tablet is-multiline">
                        {renderLoadingCards}
                    </div>
                </div>

                <div className="container">
                    <SocialShare />
                </div>
            </section>
        );
    }

    const renderRepos = repos.map((repo: Repo) => (
        <Card repo={repo} key={repo.id} />
    ));

    return (
        <section className="section is-fullheight-with-navbar">
            <div className="container">
                <div className="columns is-tablet is-multiline">
                    {renderRepos}
                    {repos.length < 4 && <PlaceholderCard />}
                </div>
            </div>
            <div className="container">
                <SocialShare />
            </div>
        </section>
    );
};
