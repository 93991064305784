import React, { useState } from "react";
import "./copy-link.scss";

interface CopyLinkProps {
    url: string;
}
export const CopyLink: React.FC<CopyLinkProps> = ({ url, children }) => {
    const [success, setSuccess] = useState(false);

    const successCopy = () => {
        setSuccess(true);
        setTimeout(() => setSuccess(false), 2000);
    };

    const fallbackCopyTextToClipboard = (text: string) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.style.position = "fixed"; //avoid scrolling to bottom
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            if (document.execCommand("copy")) {
                successCopy();
            }
        } catch (err) {}

        document.body.removeChild(textArea);
    };

    const copyTextToClipboard = (text: string) => {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(
            function() {
                successCopy();
            },
            function(err) {
                console.error("Async: Could not copy text: ", err);
            }
        );
    };

    const handleClick = () => {
        copyTextToClipboard(url);
    };

    const klasses = success ? "copy-clipboard success" : "copy-clipboard";

    return (
        <span className={klasses} onClick={handleClick}>
            {success ? "copied with success!" : children}
        </span>
    );
};
