import {
    faCaretDown,
    faCaretUp,
    faCircleNotch,
    faSearch,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import { numberFormater } from "../../helpers/formaters";
import { getReposNamesList } from "../../helpers/query-list";
import { useDebounce } from "../../helpers/use-debounce";
import history from "../../history";
import { ReposContext } from "../../providers/ReposContext";
import { Repo } from "../../repo/repo";
import { searchForRepo } from "../../repo/repo.service";
import { Placeholder } from "./Placeholder";
import "./select.scss";

export const Select: React.FC = () => {
    const { addRepo } = useContext(ReposContext);
    const ref = useRef<HTMLDivElement>(null);
    const input = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState("");
    const [results, setResults] = useState<Repo[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [isOpen, setOpen] = useSelectOpenState(ref, false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        setLoading(true);
        setValue(value);
    };

    const debouncedValue = useDebounce(value.trim(), 600);

    useEffect(() => {
        if (debouncedValue) {
            searchForRepo(debouncedValue)
                .then((repos: Repo[]) => {
                    setResults(repos);
                    setLoading(false);
                })
                .catch(() => setLoading(false));
        } else {
            setResults([]);
            setLoading(false);
        }
    }, [debouncedValue]);

    const [reposList, setReposList] = useState<string[]>([]);
    useEffect(() => {
        setReposList(getReposNamesList(history.location.pathname));

        const unlisten = history.listen(({ pathname }) => {
            setReposList(getReposNamesList(pathname));
        });

        return () => unlisten();
    }, []);

    const filtedResults = results
        .filter((repo) => !reposList.includes(repo.full_name.toLowerCase()))
        .slice(0, 10);

    const renderResults = () => {
        if (!filtedResults.length) {
            if (!debouncedValue.length) {
                return <Placeholder>Type to search...</Placeholder>;
            }

            if (debouncedValue.length && isLoading) {
                return <Placeholder>Fetching results...</Placeholder>;
            }

            if (debouncedValue.length) {
                return <Placeholder>No more results</Placeholder>;
            }
        }

        if (selectedIndex > filtedResults.length - 1) {
            setSelectedIndex(filtedResults.length - 1);
        }

        return filtedResults.map((repo, index) => {
            return (
                <li
                    key={repo.id}
                    onClick={() => addRepo(repo)}
                    className={
                        index === selectedIndex
                            ? "result-info selected"
                            : "result-info"
                    }
                >
                    <div className="info">
                        <span className="name">{repo.full_name}</span>{" "}
                        <span className="description">{repo.description}</span>
                        <span className="stars">
                            {numberFormater(repo.stargazers_count)}{" "}
                            <FontAwesomeIcon icon="star" />
                        </span>
                    </div>
                </li>
            );
        });
    };

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            switch (event.key) {
                case "Tab":
                    if (event.shiftKey) {
                        return;
                    }

                    setOpen(false);
                    return;
                case "Enter":
                    if (event.keyCode === 229) {
                        // ignore the keydown event from an Input Method Editor(IME)
                        // ref. https://www.w3.org/TR/uievents/#determine-keydown-keyup-keyCode
                        break;
                    }
                    if (isOpen) {
                        const repo = filtedResults[selectedIndex];

                        if (repo) {
                            addRepo(repo);
                        }
                        break;
                    }
                    return;
                case "Escape":
                    if (isOpen) setOpen(false);
                    break;
                case "ArrowUp":
                    if (isOpen) {
                        const nextIndex =
                            selectedIndex - 1 < 0
                                ? filtedResults.length - 1
                                : selectedIndex - 1;
                        setSelectedIndex(nextIndex);
                    }
                    break;
                case "ArrowDown":
                    if (isOpen) {
                        const prevIndex =
                            selectedIndex + 1 > filtedResults.length - 1
                                ? 0
                                : selectedIndex + 1;
                        setSelectedIndex(prevIndex);
                    }
                    break;
                default:
                    return;
            }
            event.preventDefault();
        };

        document.addEventListener("keydown", onKeyDown);

        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [isOpen, selectedIndex, filtedResults, addRepo, setOpen]);

    const handleInputKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        switch (event.key) {
            case "ArrowUp":
                if (!isOpen) {
                    setSelectedIndex(filtedResults.length - 1);
                    setOpen(true);
                }
                break;
            case "ArrowDown":
                if (!isOpen) {
                    setSelectedIndex(0);
                    setOpen(true);
                }
                break;
            default:
                if (!isOpen) setOpen(true);

                return;
        }
        event.preventDefault();
    };

    const handleClear = () => {
        setValue("");
        setResults([]);
        input.current && input.current.focus();
    };
    const searchClearIcon = value.length ? (
        <FontAwesomeIcon icon={faTimesCircle} onClick={handleClear} />
    ) : (
        <FontAwesomeIcon icon={faSearch} />
    );

    const inputLeftIcon =
        isOpen && isLoading ? (
            <FontAwesomeIcon icon={faCircleNotch} spin />
        ) : (
            searchClearIcon
        );

    const handleInputRight = (e: any, cenas: boolean) => {
        e.preventDefault();

        if (cenas) {
            input.current && input.current.focus();
        }

        setOpen(cenas);
    };

    const inputRightIcon = isOpen ? (
        <FontAwesomeIcon
            icon={faCaretUp}
            onClick={(e) => handleInputRight(e, false)}
        />
    ) : (
        <FontAwesomeIcon
            icon={faCaretDown}
            onClick={(e) => handleInputRight(e, true)}
        />
    );

    return (
        <div
            className="control has-icons-left has-icons-right"
            id="select"
            ref={ref}
        >
            <input
                type="text"
                className="input is-rounded"
                placeholder="Search for a repository"
                value={value}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                onFocus={() => setOpen(true)}
                ref={input}
            />
            <span className="icon is-small is-left">{inputLeftIcon}</span>
            <span className="icon is-small is-right">{inputRightIcon}</span>
            <div className={isOpen ? "results is-visible" : "results"}>
                {renderResults()}
            </div>
        </div>
    );
};

function useSelectOpenState(
    ref: React.RefObject<HTMLElement>,
    initialState = false
): [boolean, (e: boolean) => void] {
    const [isOpen, setOpen] = useState(initialState);

    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            if (!ref.current || ref.current.contains(event.target as Node)) {
                setOpen(true);
                return;
            }

            setOpen(false);
        };

        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, isOpen]);

    return [isOpen, setOpen];
}
