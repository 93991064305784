import React, { useEffect } from "react";
import { hotjar } from "react-hotjar";
import { Route, Router } from "react-router-dom";
import { Footer } from "./components/Footer/Footer";
import { Header } from "./components/Header/Header";
import { Hero } from "./components/Hero/Hero";
import { ReposContainer } from "./components/ReposContainer/ReposContainer";
import { initGA, PageView } from "./helpers/tracking";
import history from "./history";
import { ReposProvider } from "./providers/ReposContext";

const App: React.FC = () => {
    useEffect(() => {
        initGA();
        PageView();

        hotjar.initialize(1459974, 6);
    }, []);

    return (
        <Router history={history}>
            <ReposProvider>
                <Route path="/:repos" component={Header} />

                <Route path="/" exact component={Hero} />
                <Route path="/:repos+" component={ReposContainer} />

                <Footer />
            </ReposProvider>
        </Router>
    );
};

export default App;
