import { faTwitterSquare } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { BuyMeACoffeeBadge } from "../BuyMeACoffeeBadge/BuyMeACoffeeBadge";
import { Link } from "../Link";
import { Logo } from "../Logo";
import { Select } from "../Select/Select";
import "./introduction.scss";

interface IntroductionProps {
    showSearch?: boolean;
}
export const Introduction: React.FC<IntroductionProps> = ({
    showSearch = true
}) => {
    const logoClass = showSearch ? "logo white" : "logo";

    return (
        <div id="introduction">
            <RouterLink to="/">
                <Logo className={logoClass} />
            </RouterLink>
            <p className="title">Github Compare</p>
            <p className="subtitle">
                A better way to search and compare repos from Github side by
                side.
            </p>
            {showSearch && <Select />}
            {!showSearch && (
                <>
                <p>
                    If you have any questions or feedback reach out in our{" "}
                    <Link
                        eventLabel="twitter_feedback"
                        href="https://twitter.com/GithubComparat1"
                    >
                        Twitter{" "}
                        <FontAwesomeIcon size="lg" icon={faTwitterSquare} />
                    </Link>
                </p>
                <p className="badge">
                    <BuyMeACoffeeBadge />
                </p>
                </>
            )}
        </div>
    );
};
