import moment from "moment";
import numeral from "numeral";

export function numberFormater(value: number) {
    return numeral(value).format("0,0") || "0";
}

export function dateFromNow(value: string) {
    return moment(value, "YYYYMMDD").fromNow() || "N/A";
}
