import {
    faCalendar,
    faCertificate,
    faCodeBranch,
    faInfoCircle,
    faLanguage,
    faPlus,
    faStar
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Repo } from "../repo/repo";
import { dateFromNow, numberFormater } from "./formaters";

export const constructCardDetails = ({
    stargazers_count,
    forks,
    open_issues_count,
    license,
    language,
    created_at,
    pushed_at
}: Repo) => {
    return [
        {
            icon: <FontAwesomeIcon icon={faStar} />,
            description: "Stars",
            value: numberFormater(stargazers_count)
        },
        {
            icon: <FontAwesomeIcon icon={faCodeBranch} />,
            description: "Forks",
            value: numberFormater(forks)
        },
        {
            icon: <FontAwesomeIcon icon={faInfoCircle} />,
            description: "Open issues",
            value: numberFormater(open_issues_count)
        },
        {
            icon: <FontAwesomeIcon icon={faCalendar} />,
            description: "Age",
            value: dateFromNow(created_at)
        },
        {
            icon: <FontAwesomeIcon icon={faPlus} />,
            description: "Last commit",
            value: dateFromNow(pushed_at)
        },
        {
            icon: <FontAwesomeIcon icon={faCertificate} />,
            description: "License",
            value: (license && license.spdx_id) || "N/A"
        },
        {
            icon: <FontAwesomeIcon icon={faLanguage} />,
            description: "Language",
            value: language || "N/A"
        }
    ];
};
