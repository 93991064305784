import React from "react";
import { Link } from "react-router-dom";
import { Logo } from "../Logo";
import { Select } from "../Select/Select";
import "./header.scss";

export const Header: React.FC = () => {
    return (
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="container">
                <div className="navbar-brand">
                    <Link to="/" className="navbar-item">
                        <Logo />
                    </Link>
                </div>

                <div className="navbar-menu">
                    <div className="navbar-end">
                        <div className="navbar-item">
                            <Select />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};
