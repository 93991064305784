import pathToRegexp from "path-to-regexp";
import history from "../history";
import { REPO_SEPARATOR } from "./constants";

export function getQueryList(location: string) {
    const regexp = pathToRegexp("/:repos+");
    const result = regexp.exec(location);

    return (result && result[1]) || "";
}

export function getReposNamesList(location: string) {
    const queryList = getQueryList(location).replace(/ /g, REPO_SEPARATOR);

    if (!queryList) {
        return [];
    }

    return queryList.split(REPO_SEPARATOR);
}

export function setQueryParams(repos: string[]) {
    if (!repos) {
        return;
    }

    const reposList = repos.map((r) => r.toLowerCase()).join(REPO_SEPARATOR);

    if (!reposList.length) {
        history.push(`/`);
        return;
    }

    history.push(`/${reposList}`);
}
