import React from "react";
import "./item.scss";

enum MouseAction {
    OnEnter,
    OnLeave
}

const ITEM_HOVER_CLASS = "repo-card-item--hover";

interface ItemProps {
    icon: JSX.Element;
    description: string;
    value: string | number;
    index: number;
}
export const Item: React.FC<ItemProps> = ({
    icon,
    description,
    value,
    index
}) => {
    const changeElementClass = (
        action: MouseAction,
        element: HTMLDivElement
    ) => {
        if (action === MouseAction.OnEnter) {
            element.classList.add(ITEM_HOVER_CLASS);
        }

        if (action === MouseAction.OnLeave) {
            element.classList.remove(ITEM_HOVER_CLASS);
        }
    };

    const toggleHover = (action: MouseAction) => {
        const elementsByIndex = document.querySelectorAll(
            `.repo-card-item__${index}`
        ) as NodeListOf<HTMLDivElement>;

        elementsByIndex.forEach((element: HTMLDivElement) => {
            changeElementClass(action, element);
        });
    };

    return (
        <div
            className={`repo-card-item repo-card-item__${index}`}
            onMouseEnter={() => toggleHover(MouseAction.OnEnter)}
            onMouseLeave={() => toggleHover(MouseAction.OnLeave)}
        >
            <span className="repo-card-item-icon has-text-centered">
                {icon}
            </span>
            <span className="has-text-left repo-card-item-description">
                {description}
            </span>
            <span className="has-text-right repo-card-item-count">{value}</span>
        </div>
    );
};
