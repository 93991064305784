import {
    faGithub,
    faInstagram,
    faLinkedin,
    faProductHunt,
    faTwitter
} from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "../Link";
import "./personal-card.scss";

interface PersonalCardProps {
    name: string;
    photo: string;
    linkedin: string;
    github: string;

    instagram?: string;
    twitter?: string;
    productHunt?: string;
    website?: string;
}
export const PersonalCard: React.FC<PersonalCardProps> = ({
    name,
    photo,
    linkedin,
    github,
    instagram,
    twitter,
    productHunt,
    website,
    children
}) => {
    return (
        <div className="card personal-card">
            <div className="card-content">
                <div className="media">
                    <div className="media-left">
                        <figure className="image is-64x64">
                            <img
                                className="is-rounded"
                                src={photo}
                                alt={`${name} profile`}
                            />
                        </figure>
                    </div>
                    <div className="media-content">
                        <p className="title is-4">{name}</p>

                        <div className="social-links">
                            <Link
                                href={`https://github.com/${github}`}
                                eventLabel={`github_${github}`}
                            >
                                <FontAwesomeIcon icon={faGithub} />
                            </Link>
                            <Link
                                href={`https://linkedin.com/in/${linkedin}`}
                                eventLabel={`linkedin_${linkedin}`}
                            >
                                <FontAwesomeIcon icon={faLinkedin} />
                            </Link>
                            {instagram && (
                                <Link
                                    href={`https://instagram.com/${instagram}`}
                                    eventLabel={`instagram_${instagram}`}
                                >
                                    <FontAwesomeIcon icon={faInstagram} />
                                </Link>
                            )}
                            {twitter && (
                                <Link
                                    href={`https://twitter.com/${twitter}`}
                                    eventLabel={`twitter_${twitter}`}
                                >
                                    <FontAwesomeIcon icon={faTwitter} />
                                </Link>
                            )}
                            {productHunt && (
                                <Link
                                    href={`https://www.producthunt.com/${productHunt}`}
                                    eventLabel={`productHunt_${productHunt}`}
                                >
                                    <FontAwesomeIcon icon={faProductHunt} />
                                </Link>
                            )}
                            {website && (
                                <Link
                                    href={website}
                                    eventLabel={`website_${linkedin}`}
                                >
                                    <FontAwesomeIcon icon={faLink} />
                                </Link>
                            )}
                        </div>
                    </div>
                </div>

                <div className="content">{children}</div>
            </div>
        </div>
    );
};
