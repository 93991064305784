import React, { useContext, useEffect, useState } from "react";
import {
    Facebook,
    HackerNews,
    Linkedin,
    Mail,
    Reddit,
    Telegram,
    Twitter,
    VK,
    Whatsapp,
    Xing
} from "react-social-sharing";
import { getReposNamesList } from "../../helpers/query-list";
import history from "../../history";
import { ReposContext } from "../../providers/ReposContext";
import { Repo } from "../../repo/repo";
import { CopyLink } from "../CopyLink/CopyLink";
import "./social-share.scss";

export const SocialShare: React.FC = () => {
    const { getRepos } = useContext(ReposContext);
    const [url, setUrl] = useState(window.location.href);
    const [repos, setRepos] = useState<Repo[]>([]);

    useEffect(() => {
        const setReposFromPath = (path: string) => {
            const reposList = getReposNamesList(path);
            const repos = getRepos(reposList);
            setRepos(repos);
        };

        setReposFromPath(history.location.pathname);

        const unlisten = history.listen(({ pathname }) => {
            setReposFromPath(pathname);
            setUrl(window.location.href);
        });

        return () => unlisten();
    }, [getRepos]);

    const message = `The Github comparison between ${repos
        .map((r) => r.full_name)
        .join(" vs ")}`;

    const shareTitle = repos.length === 1 ? "this result" : "these results";

    return (
        <div className="social-share">
            <p>Share {shareTitle}:</p>
            <div className="is-multiline">
                <Facebook small solid link={url} message={message} />
                <Twitter small solid link={url} message={message} />
                <Linkedin small solid link={url} message={message} />
                <Reddit small solid link={url} message={message} />
                <Whatsapp small solid link={url} message={message} />
                <Telegram small solid link={url} message={message} />
                <VK small solid link={url} message={message} />
                <HackerNews small solid link={url} message={message} />
                <Xing small solid link={url} message={message} />
                <Mail small solid link={url} message={message} />
                <p className="copy">
                    or <CopyLink url={url}>copy link</CopyLink>
                </p>
            </div>
        </div>
    );
};
