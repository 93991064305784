import React from "react";
import "./buy-me-a-coffee.scss";

export const BuyMeACoffeeBadge: React.FC = () => {
    return (
        <>
            <link
                href="https://fonts.googleapis.com/css?family=Cookie"
                rel="stylesheet"
            />
            <a
                className="bmc-button"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.buymeacoffee.com/githubcompare"
            >
                <img
                    src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg"
                    alt="Buy us a beer"
                />
                <span
                    style={{ marginLeft: "15px", fontSize: "28px !important" }}
                >
                    Buy us a beer
                </span>
            </a>
        </>
    );
};
