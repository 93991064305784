import React from "react";

interface LogoProps {
    className?: string;
}
export const Logo: React.FC<LogoProps> = ({ className = "" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 775.66 297.8"
        className={className}
    >
        <g>
            <g>
                <path d="M114.47,79a145.25,145.25,0,0,1,76.45,0c29.16-19.74,42-15.64,42-15.64,4.93,12.46,6,20.69,5,28,6.51-16.65,14.78-29.63,26.13-43.07a152.68,152.68,0,1,0-159.6,249.31c7.62,1.41,10.42-3.33,10.42-7.36,0-3.64-.14-15.67-.21-28.43-42.48,9.25-51.45-18-51.45-18-6.93-17.64-16.93-22.33-16.93-22.33-13.87-9.49,1-9.3,1-9.3,15.34,1.09,23.4,15.73,23.4,15.73,13.62,23.36,35.72,16.61,44.45,12.7,1.36-9.86,5.33-16.61,9.69-20.41-33.92-3.87-69.56-17-69.56-75.46a59,59,0,0,1,15.73-41c-1.6-3.85-6.82-19.39,1.46-40.41C72.46,63.31,85.3,59.21,114.47,79Z" />
                <path d="M180.36,220c5.49,4.72,10.35,14,10.35,28.26,0,20.43-.16,36.87-.16,41.9,0,4.08,2.76,8.82,10.49,7.33a152.54,152.54,0,0,0,62.89-40.29,161.85,161.85,0,0,1-31.47-58.5C218.92,212.93,199.37,217.91,180.36,220Z" />
                <path d="M53.85,217.37c-.35.75.26,1.79,1.37,2.29s2.29.3,2.62-.45-.26-1.82-1.37-2.31S54.18,216.59,53.85,217.37Z" />
                <path d="M60.46,222.23c-.73.68-.57,2.1.45,3.16s2.38,1.39,3.11.71.56-2.1-.43-3.17S61.21,221.54,60.46,222.23Z" />
                <path d="M66.64,229.94c-1,.64-1,2.27,0,3.63s2.45,2,3.39,1.32,1-2.28,0-3.68S67.6,229.28,66.64,229.94Z" />
                <path d="M73.5,238.9c-.85.92-.47,2.67.85,3.9s3.09,1.51,3.94.59.47-2.67-.85-3.9S74.35,238,73.5,238.9Z" />
                <path d="M83.34,246.41c-.36,1.22.78,2.61,2.5,3.13s3.44,0,3.82-1.22-.78-2.6-2.5-3.14S83.69,245.2,83.34,246.41Z" />
                <path d="M95.6,249.33c0,1.25,1.49,2.26,3.31,2.22s3.3-1.06,3.25-2.31-1.48-2.27-3.3-2.24S95.6,248.08,95.6,249.33Z" />
                <path d="M110.13,245.58c-1.76.33-3.06,1.58-2.83,2.85s1.87,2,3.64,1.66,3.06-1.61,2.85-2.84S111.93,245.27,110.13,245.58Z" />
                <path d="M387.91,0a152.7,152.7,0,0,0-48.26,297.55c7.64,1.41,10.45-3.33,10.45-7.36,0-3.64-.14-15.67-.21-28.43-16.42,3.59-27.84,1.7-35.67-2a33.38,33.38,0,0,1-7.78-5,28,28,0,0,1-8-11c-.12-.33-.26-.63-.4-1a2.61,2.61,0,0,0-.12-.29,58.75,58.75,0,0,0-3.35-6.91c-1-1.72-2-3.23-2.95-4.6a43.76,43.76,0,0,0-3.49-4.24c-.44-.48-.82-.85-1.22-1.25a28,28,0,0,0-3.49-2.91,13.67,13.67,0,0,0-1.91-1.18c-.38-.25-.76-.51-1.11-.77a19.82,19.82,0,0,1-3.31-2.86c-1.69-1.81-1.77-3.06-1.08-3.89a.75.75,0,0,1,.28-.28c1.75-1.54,6.25-1.49,6.25-1.49a25.36,25.36,0,0,1,9.65,2.69,35.23,35.23,0,0,1,13.75,13,37.23,37.23,0,0,0,8.07,9.89,28.29,28.29,0,0,0,18.59,6.82,44.3,44.3,0,0,0,17.76-4c1.39-9.86,5.33-16.61,9.69-20.41-22.64-2.59-46-9.27-59.11-30.88-6.51-10.73-10.45-25.17-10.45-44.58A59.29,59.29,0,0,1,299.86,112a60.73,60.73,0,0,1,6.37-8.25c-1.59-3.85-6.8-19.39,1.48-40.41,0,0,12.83-4.1,42,15.64a145.33,145.33,0,0,1,76.47,0c29.13-19.74,41.94-15.64,41.94-15.64a60.12,60.12,0,0,1,4.32,27.89h.14c1.27-6.48,5.5-13.8,8.63-19.25.68-1.18,5.91-8.51,10.67-14.61,3.86-4.94,7.41-9.07,8-8.46A152.17,152.17,0,0,0,387.91,0Z" />
                <path d="M415.61,220c5.47,4.72,10.35,14,10.35,28.26,0,20.43-.16,36.87-.16,41.9,0,4.08,2.73,8.82,10.47,7.33A152.79,152.79,0,0,0,500.33,256a161.27,161.27,0,0,1-31-59.06C455.78,212.53,435.4,217.82,415.61,220Z" />
                <path d="M623,0A152.16,152.16,0,0,0,510.5,49.47c-2.17,2.33-4.24,4.71-6.23,7.19a151.85,151.85,0,0,0-29.13,57.77,145.13,145.13,0,0,0-3.3,16.63,152.34,152.34,0,0,0-1.53,21.6c0,5.17.26,10.29.75,15.34a155.08,155.08,0,0,0,3,18.54v0a152.21,152.21,0,0,0,30.19,62.13q3,3.75,6.3,7.29a152.74,152.74,0,0,0,64.14,41.57c7.64,1.41,10.43-3.33,10.43-7.36,0-3.64-.15-15.67-.19-28.43-42.49,9.25-51.45-18-51.45-18-.14-.37-.28-.73-.45-1.08v0a56.24,56.24,0,0,0-3.68-7.45c-.73-1.27-1.46-2.43-2.19-3.49-.14-.17-.26-.35-.38-.52v-.05c-.49-.68-1-1.25-1.41-1.84h0a37.21,37.21,0,0,0-4.41-4.71h0c-.17-.17-.31-.29-.45-.41a20.54,20.54,0,0,0-3.94-2.75c-.61-.43-1.16-.81-1.65-1.21-8.73-6.6-1.77-7.83,1.37-8,.8-.05,1.34-.05,1.34-.05a24.83,24.83,0,0,1,7.83,1.86,3.42,3.42,0,0,1,.4.17,29.64,29.64,0,0,1,7.69,4.86v0a20.28,20.28,0,0,1,1.65,1.51c.67.64,1.28,1.3,1.82,1.91a33.09,33.09,0,0,1,4,5.4c.31.52.61,1,.92,1.51s.52.81.78,1.18A34.25,34.25,0,0,0,549,237.7a5.08,5.08,0,0,0,.43.38,28.06,28.06,0,0,0,9,5l0,0a29.88,29.88,0,0,0,10.34,1.46c.07,0,.11,0,.18,0h0a41.08,41.08,0,0,0,10.8-1.86,50,50,0,0,0,5.64-2.12c.16-1.25.4-2.46.64-3.61,1.74-7.93,5.23-13.47,9.05-16.8-19.69-2.24-40-7.62-53.57-23.21v0a54.94,54.94,0,0,1-7.07-10.31c-5.59-10.43-8.92-24.06-8.92-41.92a59,59,0,0,1,14.86-40q.42-.49.87-1c-1.58-3.85-6.81-19.39,1.49-40.41,0,0,12.81-4.1,42,15.64a145.33,145.33,0,0,1,76.47,0c29.13-19.74,41.94-15.64,41.94-15.64,8.31,21,3.09,36.56,1.49,40.41,9.79,10.68,15.71,24.29,15.71,41,0,58.64-35.71,71.55-69.71,75.34,5.48,4.72,10.36,14,10.36,28.26,0,20.43-.17,36.87-.17,41.9,0,4.08,2.74,8.82,10.48,7.33A152.69,152.69,0,0,0,623,0Z" />
                <path d="M530.48,219.21c.4-.78-.23-1.82-1.34-2.31s-2.31-.31-2.65.47.26,1.79,1.37,2.29S530.15,220,530.48,219.21Z" />
                <path d="M533.15,224.85a2.15,2.15,0,0,0,.4.54c1,1.06,2.4,1.39,3.13.71s.55-2.1-.44-3.17a3.47,3.47,0,0,0-1.7-1,.56.56,0,0,0-.17,0,1.48,1.48,0,0,0-1.27.33,1.39,1.39,0,0,0-.45,1A2.85,2.85,0,0,0,533.15,224.85Z" />
                <path d="M538.9,232.89a3.32,3.32,0,0,0,.38.68,3.83,3.83,0,0,0,1.27,1.21,2,2,0,0,0,2.15.11,2,2,0,0,0,.66-2.14,4.22,4.22,0,0,0-.64-1.54,4.28,4.28,0,0,0-.8-.84,2.23,2.23,0,0,0-2.62-.43A2.33,2.33,0,0,0,538.9,232.89Z" />
                <path d="M547.21,243a.33.33,0,0,0,.16.12c1.25,1,2.81,1.16,3.56.31s.59-2.15-.28-3.31a5,5,0,0,0-.54-.59,3.15,3.15,0,0,0-.43-.35c-1.25-.94-2.78-1.06-3.54-.24s-.47,2.67.88,3.9C547.09,242.84,547.13,242.91,547.21,243Z" />
                <path d="M558.17,249.42a1.31,1.31,0,0,0,.33.12,1.25,1.25,0,0,0,.31.07c1.63.38,3.16-.16,3.52-1.29s-.55-2.29-2-2.93a2.74,2.74,0,0,0-.54-.21,1.09,1.09,0,0,1-.17-.05c-1.7-.45-3.3.12-3.66,1.28S556.64,248.83,558.17,249.42Z" />
                <path d="M571.5,247a2.85,2.85,0,0,0-.94.14c-1.35.28-2.31,1.18-2.31,2.19s1.2,2,2.78,2.19h.14a1.71,1.71,0,0,0,.4,0c1.82,0,3.28-1.06,3.23-2.31s-1.34-2.15-3-2.22A1.27,1.27,0,0,0,571.5,247Z" />
                <path d="M580,248.43c.23,1.21,1.86,2,3.65,1.66a5.61,5.61,0,0,0,.62-.17c1.46-.5,2.4-1.58,2.21-2.67s-1.37-1.74-2.83-1.74a4.54,4.54,0,0,0-.82.07C581,245.91,579.73,247.16,580,248.43Z" />
                <path d="M295.26,221.57c.4-.78-.24-1.82-1.34-2.32s-2.32-.3-2.65.48.26,1.79,1.37,2.28S294.93,222.32,295.26,221.57Z" />
                <path d="M297.93,227.2a2,2,0,0,0,.4.55c1,1.06,2.4,1.39,3.13.7s.55-2.09-.44-3.16a3.47,3.47,0,0,0-1.7-1,.56.56,0,0,0-.17,0,1.45,1.45,0,0,0-1.27.33,1.35,1.35,0,0,0-.45,1A2.8,2.8,0,0,0,297.93,227.2Z" />
                <path d="M303.68,235.25a2.91,2.91,0,0,0,.38.68,3.83,3.83,0,0,0,1.27,1.21,2,2,0,0,0,2.15.11,2,2,0,0,0,.66-2.14,4.4,4.4,0,0,0-.64-1.54,4.65,4.65,0,0,0-.8-.85,2.23,2.23,0,0,0-2.62-.42A2.33,2.33,0,0,0,303.68,235.25Z" />
                <path d="M312,245.32a.52.52,0,0,0,.17.12c1.25,1,2.81,1.15,3.56.3s.59-2.14-.28-3.3a5,5,0,0,0-.54-.59,3.15,3.15,0,0,0-.43-.35c-1.25-.94-2.78-1.06-3.54-.24s-.47,2.67.87,3.9C311.87,245.2,311.91,245.27,312,245.32Z" />
                <path d="M323,251.78a1.18,1.18,0,0,0,.33.12,1.73,1.73,0,0,0,.31.07c1.63.38,3.16-.16,3.51-1.3s-.54-2.28-2-2.92a2.16,2.16,0,0,0-.55-.21s-.11,0-.16,0c-1.7-.45-3.3.12-3.66,1.27S321.42,251.19,323,251.78Z" />
                <path d="M336.28,249.35a2.85,2.85,0,0,0-.94.15c-1.35.28-2.32,1.17-2.32,2.19s1.21,2.05,2.79,2.19H336a1.71,1.71,0,0,0,.4,0c1.82,0,3.28-1.07,3.23-2.32s-1.34-2.14-3-2.21A.89.89,0,0,0,336.28,249.35Z" />
                <path d="M344.72,250.79c.24,1.21,1.87,2,3.66,1.65a3.62,3.62,0,0,0,.61-.16c1.47-.5,2.41-1.58,2.22-2.67s-1.37-1.74-2.83-1.74a4.54,4.54,0,0,0-.82.07C345.79,248.27,344.51,249.52,344.72,250.79Z" />
            </g>
        </g>
    </svg>
);
