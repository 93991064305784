import React from "react";
import DiogoBatista from "../../assets/diogo-batista.jpg";
import MiguelCorreia from "../../assets/miguel-correia.jpg";
import { Introduction } from "../Hero/Introduction";
import { Link } from "../Link";
import { PersonalCard } from "../PersonalCard/PersonalCard";
import "./footer.scss";

export const Footer: React.FC = () => (
    <footer className="footer">
        <div className="container">
            <div className="columns">
                <div className="column is-4 github-compare-column">
                    <div>
                        <Introduction showSearch={false} />
                    </div>
                </div>
                <div className="column is-4">
                    <PersonalCard
                        name="Diogo Batista"
                        photo={DiogoBatista}
                        linkedin="diogobvbatista"
                        github="DiogoBatista"
                        twitter="DiogoBa36906055"
                        productHunt="@diogo_batista"
                    >
                        <p>
                            I love to build tools on my free time and see if
                            they could be of some use to anyone!
                        </p>
                        <p>
                            I'm always exploring repositories on Github, and
                            jumping from tab to tab to compare different
                            packages, without being able to see them side by
                            side, so I created this small tool.
                        </p>
                        <p>
                            For more tools, check out my{" "}
                            <Link
                                href="https://linkedin.com/in/diogobvbatista"
                                eventLabel="linkedin_diogobvbatista"
                            >
                                Linkedin
                            </Link>{" "}
                            <span role="img" aria-label="smile">
                                😎
                            </span>
                        </p>
                    </PersonalCard>
                </div>
                <div className="column is-4">
                    <PersonalCard
                        name="Miguel Correia"
                        photo={MiguelCorreia}
                        linkedin="miguelncorreia"
                        github="sircon"
                        instagram="sircon1"
                        website="https://www.miguelncorreia.com"
                        productHunt="@sircon"
                    >
                        <p>
                            I'm a developer who loves to build ideas from
                            scratch.
                        </p>
                        <p>
                            As a developer I spend a lot of time looking into
                            repos and comparing similar tools on Github.
                            Building this app has helped me explore Github and
                            alternative repos more efficiently.
                        </p>
                        <p>
                            Check other stuff I did on my{" "}
                            <Link
                                eventLabel="github_sircon"
                                href="https://github.com/sircon"
                            >
                                Github
                            </Link>
                            .
                        </p>
                    </PersonalCard>
                </div>
            </div>
        </div>
        <div className="copywrite">
            <div className="content has-text-centered">
                <p>
                    <strong>Github Compare</strong> by{" "}
                    <Link
                        href="https://linkedin.com/in/diogobvbatista"
                        eventLabel="linkedin_diogobvbatista"
                    >
                        Diogo Batista
                    </Link>{" "}
                    and{" "}
                    <Link
                        href="https://www.miguelncorreia.com"
                        eventLabel="website_miguelncorreia"
                    >
                        Miguel Correia
                    </Link>
                    . Made with{" "}
                    <span role="img" aria-label="heart">
                        ❤️
                    </span>
                    in Amsterdam.
                </p>
            </div>
        </div>
    </footer>
);
