import ReactGA, { EventArgs } from "react-ga";

const TRACKING_ID = "UA-145421908-1";

export const GAEvent = ({ category, action, label }: EventArgs) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};

export const initGA = () => {
  ReactGA.initialize(TRACKING_ID);
};

export const PageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};
