import React from "react";

interface TitleProps {
    full_name: string;
    avatar_url: string;
    url: string;
}
export const Title: React.FC<TitleProps> = ({ full_name, avatar_url, url }) => (
    <div className="repo-card-title">
        <a href={url} target="_blank" rel="noopener noreferrer">
            {full_name}
        </a>
        <a href={url} target="_blank" rel="noopener noreferrer">
            <img className="repo-card-avatar" src={avatar_url} alt="repo-img" />
        </a>
    </div>
);
