import React, { createContext, useEffect, useState } from "react";
import {
    getQueryList,
    getReposNamesList,
    setQueryParams
} from "../helpers/query-list";
import history from "../history";
import { Repo } from "../repo/repo";
import { fetchRepos } from "../repo/repo.service";

interface IRepos {
    getRepos: (reposList: string[]) => Repo[];
    addRepo: (repo: Repo) => void;
    removeRepo: (repoName: string) => void;
    isLoading: boolean;
}

const ReposContext = createContext<IRepos>({
    getRepos: () => [],
    addRepo: () => {},
    removeRepo: () => {},
    isLoading: true
});

const ReposProvider: React.FC = ({ children }) => {
    const [allRepos, setAllRepos] = useState<Repo[]>([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        const getInitialRepos = async () => {
            const queryList = getQueryList(history.location.pathname);

            if (!queryList) {
                setLoading(false);
                return;
            }

            const repos = await fetchRepos(queryList);

            setAllRepos(repos);
            setQueryParams(repos.map(({ full_name }) => full_name));
            setLoading(false);
        };

        getInitialRepos();
        // We only want to run this effect on load
        // eslint-disable-next-line
    }, []);

    const addRepo = (repo: Repo) => {
        const hasRepo =
            allRepos.findIndex(
                ({ full_name }) => repo.full_name === full_name
            ) >= 0;

        if (!hasRepo) {
            setAllRepos([...allRepos, repo]);
        }

        const reposList = getReposNamesList(history.location.pathname);
        reposList.push(repo.full_name);

        setQueryParams(reposList);
    };

    const removeRepo = (repoName: string) => {
        const reposList = getReposNamesList(history.location.pathname);
        const deleteRepoIndex = reposList.findIndex(
            (r) => r === repoName.toLowerCase()
        );

        if (deleteRepoIndex >= 0) {
            reposList.splice(deleteRepoIndex, 1);
        }

        const repos = allRepos
            .filter(({ full_name }) =>
                reposList.includes(full_name.toLowerCase())
            )
            .map(({ full_name }) => full_name);

        setQueryParams(repos);
    };

    const getRepos = (reposList: string[]): Repo[] => {
        const repos: Repo[] = [];

        reposList.forEach((full_name) => {
            const repo = allRepos.find(
                (r) => r.full_name.toLowerCase() === full_name.toLowerCase()
            );

            if (repo) {
                repos.push(repo);
            }
        });
        return repos;
    };

    return (
        <ReposContext.Provider
            value={{
                getRepos,
                addRepo,
                removeRepo,
                isLoading
            }}
        >
            {children}
        </ReposContext.Provider>
    );
};

export { ReposContext, ReposProvider };
